body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'RobotoSlab';
  src: local('RobotoSlab'), url(./Fonts/RobotoSlab-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'KiwiMaru';
  src: local('KiwiMaru'), url(./Fonts/KiwiMaru-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'AmaticSC-Regular';
  src: local('AmaticSC-Regular'), url(./Fonts/AmaticSC-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'AmaticSC-Bold';
  src: local('AmaticSC-Bold'), url(./Fonts/AmaticSC-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SpecialElite';
  src: local('SpecialElite'), url(./Fonts/SpecialElite-Regular.ttf) format('truetype');
}
