div {
  white-space: pre-wrap;
}

body:not(.keyboardUser) *:focus {
  outline: none;
}

.audio-icon {
  position: fixed;
  top: 80vh;
  left: 90vw;
}

.button-text {
  font-family: KiwiMaru;
  font-size: 11px;
  color: white;
  margin: 0px;
}

.bird {
  position: absolute;
  width: 200px;
}

.small-rock {
  position: absolute;
  width: 150px;
}

.large-rock {
  position: absolute;
  width: 275px;
}

.plant {
  position: absolute;
  width: 225px;
}

.welcome-text {
  font-family: KiwiMaru;
  font-size: 32px;
  text-align: center;
  margin-top: 20px;
}

.question-header {
  font-family: SpecialElite;
  font-size: 30px;
  text-align: center;
  padding-top: 25px;
}

.question-text {
  font-family: SpecialElite;
  font-size: 18px;
  text-align: center;
}

.answer-text {
  font-family: SpecialElite;
  font-size: 22px;
  text-align: center;
}

.left-question-text {
  font-family: SpecialElite;
  font-size: 18px;
  text-align: left;
}

.words-left {
  font-family: SpecialElite;
  font-size: 16px;
  display: inline;
  float: right;
  margin-top: 8px;
  margin-right: 15px;
}

.response-submitted {
  font-family: SpecialElite;
  font-size: 16px;
  display: inline;
  float: left;
  margin-top: 8px;
  color: green;
}

.credits-header {
  font-family: AmaticSC-Bold;
  font-size: 58px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
}

.credits-text {
  font-family: RobotoSlab;
  font-size: 17px;
  text-align: center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 750px;
  left: 0;
  right: 0;
}

.background-audio-text {
  position: fixed;
  font-family: KiwiMaru;
  font-size: 17px;
}

.audio-icon {
  position: absolute;
  z-index: 3;
}

.close-icon {
  float: right;
  color: black;
}

.submit-button {
  display: inline;
  float: right;
  font-family: SpecialElite;
  font-size: 20px;
}