.title-text {
  display: block;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
}

.mobile-title-text {
  display: block;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 85vw;
}

.small-breadcrumb {
  position: absolute;
  width: 100px;
}

.small-interactive-breadcrumb {
  position: absolute;
  width: 100px;
}

.small-interactive-breadcrumb:hover {
  filter: brightness(80%);
  width: 107px;
}

.small-trio {
  position: absolute;
  width: 200px;
}

.small-clump {
  position: absolute;
  width: 225px;
}

.large-clump {
  position: absolute;
  width: 300px;
}

.large-interactive-clump {
  position: absolute;
  width: 300px;
}

.large-interactive-clump:hover {
  filter: brightness(80%);
  width: 307px;
}

.assorted-clump {
  position: absolute;
  width: 550px;
}

.assorted-interactive-clump {
  position: absolute;
  width: 550px;
}

.assorted-interactive-clump:hover {
  filter: brightness(80%);
  width: 557px;
}

.medium-breadcrumb {
  position: absolute;
  width: 150px;
}

.medium-interactive-breadcrumb {
  position: absolute;
  width: 150px;
}

.medium-interactive-breadcrumb:hover {
  filter: brightness(80%);
  width: 157px;
}

.large-breadcrumb {
  position: absolute;
  width: 200px;
}

.large-interactive-breadcrumb {
  position: absolute;
  width: 200px;
}

.large-interactive-breadcrumb:hover {
  filter: brightness(80%);
  width: 207px;
}

.ex-large-interactive-breadcrumb {
  position: absolute;
  width: 250px;
}

.ex-large-interactive-breadcrumb:hover {
  filter: brightness(80%);
  width: 257px;
}

.landing-bird {
  position: absolute;
  width: 300px;
}

.landing-bird:hover {
  transform: rotate(7deg);
}

.mobile-bird {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.landing-text {
  font-family: KiwiMaru;
  font-size: 24px;
  position: absolute;
  top: 90vh;
  left: 70vw;
}

.mobile-landing-text {
  font-family: KiwiMaru;
  font-size: 22px;
  margin: 20px;
  text-align: center;
}